import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Button, Heading, Paragraph, ResponsiveImage, Spacer } from '@atoms/index'
import { LowStockContainer } from '@client/components/organisms'
import { MealkitModalEnum } from '@client/components/organisms/modals/MealkitModal'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ProductCardContainer, ProductCardContentContainer, ProductCardImageContainer } from '@components/Theme'
import { useUserDetailsQuery, useUserCartQuery, UserCartDocument, useAddItemToCartMutation, useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation, WineListFragment } from '@hooks/api'
import { FloatingUpSell } from '@molecules/index'
import { ProductStockStatusEnum, ProductAvailabilityEnum } from '@uctypes/api/globalTypes'
import { useEvents } from '@contexts/GTMProvider'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'

const Container = styled.div`
  ${ProductCardContainer}
`

const ImageContainer = styled.div`
  ${ProductCardImageContainer}
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`

const ContentContainer = styled.div`
  ${ProductCardContentContainer}
    flex: 1;
    justify-content: space-between;
`
const StaticContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const ActionContainer = styled.div`
  display: flex;
`
export interface WineCardProps {
  wine: WineListFragment
  className?: string
  loading?: boolean
}

interface WineCardState {
  chosenPairing: number
  amount: number
  addToCartInterrupted: boolean
  outOfStock: boolean
  activeModal: MealkitModalEnum
  loading: boolean
  currentAmountInCart: number
  displayWineDiscount: boolean
}

const DEFAULT_STATE: WineCardState = {
  chosenPairing: 0,
  amount: 1,
  addToCartInterrupted: false,
  outOfStock: false,
  activeModal: MealkitModalEnum.NONE,
  loading: false,
  currentAmountInCart: null,
  displayWineDiscount: false,
}

export const WineCard = React.memo(({ wine, className, loading = false }: WineCardProps): JSX.Element => {

  const config = useConfig()
  const [state, setState] = useState<WineCardState>(DEFAULT_STATE)
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addItemToCart] = useAddItemToCartMutation()
  const { addToast } = useToasts()
  const pdpUrl = `/wine/${wine.slug}`
  const uiLoading = state.loading || userDetailsLoading || loading
  const { data: userCartData, refetch } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries(),
    awaitRefetchQueries: true,
  })
  const events = useEvents()

  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const cartItems = userCartData?.currentUser?.activeCart?.cartItems || []

  const isOutOfStock = (): boolean => {
    return wine.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK
  }

  const isDeliveryUnavailable = (): boolean => {
    return wine.availability === ProductAvailabilityEnum.NOT_AVAILABLE
  }

  const isDeliveryDayUnavailable = (): boolean => {
    return wine.availability === ProductAvailabilityEnum.UNAVAILABLE_ON_DELIVERY_DAY
  }
  const _handleOnRemove = async (): Promise<void> => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => update(prevState, { addToCartInterrupted: { $set: noDefaultAddress } }))

    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else {

      try {
        setState((prevState) => update(prevState, { loading: { $set: true } }))

        if (state.currentAmountInCart === 1) {

          await removeItemFromCart({
            variables: {
              productId: wine.id,
            },
            refetchQueries: SiteHelper.getUserRefetchQueries(),
            awaitRefetchQueries: true,
          })
          setState((prevState) => update(prevState, { currentAmountInCart: { $set: 0 } }))
        } else {
          await changeCartItemQuantity({
            variables: {
              productId: wine.id,
              quantity: state.currentAmountInCart - 1,
            },
          })
        }
        
        const logData = {
          itemName: wine?.name,
          itemId: wine?.id,
          price: wine?.price,
          itemBrand: 'UCOOK',
          itemCategory: wine?.wineCategory?.id,
          itemVariant: wine?.wineCategory?.title,
          itemListName: 'Wines',
          quantity: 1,
          itemImage: wine?.coverImage?.location,
          itemStockCount: wine?.stockCount > 1 ? 1 : 0,
          itemChef: wine?.vineyard?.name,
          isMealkit: 'no',
          itemServingSize:1,
        }
        const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

        events.hasRemovedFromCart(snakedData)

        addToast('Item successfully removed from cart.', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      setState((prevState) => update(prevState, { loading: { $set: false } }))

    }
  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (!(state.currentAmountInCart > 0)) {
      _handleOnAdd()
    }
  }
  const _handleOnAdd = (): void => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => update(prevState, { addToCartInterrupted: { $set: noDefaultAddress || isDeliveryUnavailable() || isDeliveryDayUnavailable() } }))

    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else if (isDeliveryUnavailable()) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    } else if (isDeliveryDayUnavailable()) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_DAY_UNAVAILABLE)
    } else {
      _handleAddToCart()
    }
  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, isDeliveryUnavailable(), isDeliveryDayUnavailable()])

  const _handleAddToCart = async (): Promise<void> => {
    _setLoading(true)
    try {
      await addItemToCart({
        variables: { productId: wine?.id, quantity: state.amount },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })

      const logData = {
        itemName: wine?.name,
        itemId: wine?.id,
        price: wine?.price,
        itemBrand: wine?.vineyard?.name,
        itemCategory: 'Wines',
        itemVariant: wine?.wineCategory?.title,
        itemListName: 'Wines',
        quantity: state.amount,
        itemImage: wine?.coverImage?.location,
        itemStockCount: wine?.stockCount > 1 ? 1 : 0,
        itemChef: wine?.vineyard?.name,
        isMealkit: 'no',
        itemServingSize:1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasAddedToCart(snakedData)
      addToast(`Successfully added (${state.amount}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)
  }

  const isCardItemInBasket = () => {

    const quantity = cartItems?.find((item) => item?.product?.id === wine?.id)?.quantity || 0

    setState((prevState) => update(prevState, {
      currentAmountInCart: { $set: quantity },
    }))
  }

  useEffect(() => {
    refetch()
    isCardItemInBasket()
  }, [userCartData])

  const _setLoading = (loading: boolean) => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  useEffect(() => {
    if (wine) {
      setState((prevState) => update(prevState, {
        outOfStock: { $set: isOutOfStock() },
      }))
    }
  }, [])

  return (
    <Container className={className}>
      <If condition={wine?.upSellText?.length > 0}>
        <FloatingUpSell text={wine.upSellText} />
      </If>
      <LinkContainer href={pdpUrl}>
        <ImageContainer>
          <ResponsiveImage image={wine.coverImage} lazy={false} />
        </ImageContainer>
      </LinkContainer>
      <ContentContainer>
        <StaticContainer>
          <Heading className='card-title' variant='h6'> {wine?.name} </Heading>
          <Spacer universal='4px' />
          <If condition={!!wine?.maker?.name}>
            <Paragraph className='card-sub-title' variant='p2'>by {wine?.maker?.name} </Paragraph>
            <Spacer universal='4px' />
          </If>
          <LowStockContainer product={wine}>
            <Heading variant='h5'> {`R${wine?.price}`} </Heading>
          </LowStockContainer>
          <Spacer universal='16px' />
        </StaticContainer>
        <ActionContainer>
          <Button
            className='add-button'
            title={state.outOfStock ? 'OUT OF STOCK' : 'ADD TO CART'}
            color='black'
            fullWidth
            loading={loading || state.loading || uiLoading}
            disabled={loading}
            amount={state.currentAmountInCart}
            onClick={_handleOnCenterClick}
            onLeftIconClick={_handleOnRemove}
            onRightIconClick={_handleOnAdd} />
        </ActionContainer>
      </ContentContainer>
    </Container>
  )

})

WineCard.displayName = 'WineCard'
