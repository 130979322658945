import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled from 'styled-components'

import { GlobalModalTypeEnum, ModalsPlugin } from '@api/local/ModalsPlugin'
import { ResponsiveImage, Button, Spacer, Heading, Paragraph } from '@atoms/index'
import { LowStockContainer } from '@client/components/organisms'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ProductCardContainer, ProductCardImageContainer, ProductCardContentContainer } from '@components/Theme'
import { useUserDetailsQuery, useChangeCartItemQuantityMutation, useAddItemToCartMutation, useRemoveItemFromCartMutation, UserCartDocument, FrozenMealDishListFragment, FrozenMealListFragment } from '@hooks/api'
import { FrozenPortionSizeEnum, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

import { FloatingUpSell } from './FloatingUpSell'
import { useEvents } from '@contexts/GTMProvider'
import { Utilities } from '@lib/Utilities'
import { ItemInterfaceNew } from '@lib/GTM'

const Container = styled.div`
  ${ProductCardContainer}
`

const ImageContainer = styled.div`
  ${ProductCardImageContainer}
`

const LinkContainer = styled.a`
  cursor: pointer;
  text-decoration: none;
`
const DescriptionContainer = styled.div`
  height: 3em;
`
const ContentContainer = styled.div`
  ${ProductCardContentContainer}
  .serving-size {
    display: flex;
    flex: 0;
    margin: 0;
  }
`
export interface CraftDessertCardProps {
  craftDessertMeal: FrozenMealDishListFragment
  className?: string
  loading?: boolean
}

interface CraftDessertCardState {
  addToCartInterrupted: boolean
  loading: boolean
  servingSize: FrozenPortionSizeEnum
}

const DEFAULT_STATE: CraftDessertCardState = {
  addToCartInterrupted: false,
  loading: false,
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,
}

export function CraftDessertCard({ craftDessertMeal, className, loading = false }: CraftDessertCardProps): JSX.Element {

  const config = useConfig()
  const navigate = useNavigate()
  const { addToast } = useToasts()
  const [state, setState] = useState<CraftDessertCardState>({ ...DEFAULT_STATE })
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addItemToCart] = useAddItemToCartMutation()
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries(),
    awaitRefetchQueries: true,
  })
  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const pdpLink = `/frozen/craft-dessert/${craftDessertMeal.slug}`
  const uiLoading = state.loading || userDetailsLoading || loading
  const product = craftDessertMeal?.products?.find((product) => { return product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })
  const setLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, { loading: { $set: loading } }))
  }

  const events = useEvents()
  const isAvailable = (): boolean => {
    return craftDessertMeal?.products?.find((dish) => { return dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE })?.availability !== ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY
  }
  const _handleLinkClicked = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate(pdpLink)
  }

  const _handleOnAdd = (): void => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => update(prevState, { addToCartInterrupted: { $set: noDefaultAddress || !isAvailable() } }))

    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else if (isAvailable()) {
      _handleAddToCart()
    } else {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    }
  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, isAvailable()])

  const _handleAddToCart = async (): Promise<void> => {

    setLoading(true)

    try {
      await addItemToCart({
        variables: { productId: product?.id, quantity: 1 },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })



      const logData = {
        itemName: craftDessertMeal?.name,
        itemId: product?.id,
        price: product?.price,
        itemBrand: 'Craft Kids',
        itemCategory: 'Craft Kids',
        itemVariant: craftDessertMeal?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Kids',
        quantity: 1,
        itemImage: craftDessertMeal?.coverImage?.location,
        itemStockCount: product?.stockCount > 1 ? 1 : 0,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakeCaseLogData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasAddedToCart(snakeCaseLogData)

      addToast('Item successfully added to cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)
  }
  const _handleOnRemove = async (): Promise<void> => {

    setLoading(true)
    const product = craftDessertMeal?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })

    try {
      if (product.quantityInCart === 1) {
        await removeItemFromCart({
          variables: {
            productId: product.id,
          },
          refetchQueries: SiteHelper.getUserRefetchQueries(),
          awaitRefetchQueries: true,
        })
      } else {
        await changeCartItemQuantity({
          variables: {
            productId: product.id,
            quantity: product.quantityInCart - 1,
          },
        })
      }

      const logData = {
        itemName: craftDessertMeal?.name,
        itemId: product?.id,
        price: product?.price,
        itemBrand: 'UCOOK',
        itemCategory: craftDessertMeal?.frozenCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: craftDessertMeal?.frozenCategories?.map((cat) => cat.title)?.join(', '),
        itemListName: 'Craft Meals',
        quantity: 1,
        itemImage: craftDessertMeal?.coverImage?.location,
        itemStockCount: product?.stockCount > 1 ? 1 : 0,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }
      const snakeCaseLogData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakeCaseLogData)

      
      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setLoading(false)

  }

  const _handleOnCenterClick = async (): Promise<void> => {
    if (product.quantityInCart === 0) {
      _handleOnAdd()
    }
  }

  let notAvailable = false
  let buttonTitle = 'ADD TO CART'
  let craftDessertMealProduct: FrozenMealListFragment

  for (let i = 0; i < craftDessertMeal?.products.length; i++) {
    if (craftDessertMeal?.products[i].frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE) {
      craftDessertMealProduct = craftDessertMeal?.products[i]
    }
  }

  if (craftDessertMealProduct?.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
    notAvailable = true
    buttonTitle = 'OUT OF STOCK'
  } else if (craftDessertMealProduct?.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE) {
    buttonTitle = 'GET STARTED'
  } else if (craftDessertMealProduct?.availability !== ProductAvailabilityEnum.AVAILABLE) {
    notAvailable = true
    buttonTitle = 'NOT AVAILABLE'
  }

  return (
    <Container className={className}>
      <If condition={craftDessertMeal?.upSellText?.length > 0}>
        <FloatingUpSell text={craftDessertMeal.upSellText} />
      </If>
      <LinkContainer onClick={_handleLinkClicked}>
        <ImageContainer>
          <ResponsiveImage image={craftDessertMeal.coverImage} lazy={false} />
        </ImageContainer>
      </LinkContainer>
      <ContentContainer className='fluid-grow'>
        <Heading className='card-title' variant='h6'> {craftDessertMeal.name} </Heading>
        <Spacer universal='4px' />
        <DescriptionContainer>
          <Paragraph className='card-sub-title' variant='p2'> {craftDessertMeal.subTitle} </Paragraph>
        </DescriptionContainer>
        <Spacer universal='4px' />
        <LowStockContainer product={product}>
          <Heading variant='h5'> {`R${product.price}`} </Heading>
        </LowStockContainer>
        <Spacer universal='16px' className='fluid-grow' />
        <Button
          className='add-button'
          title={buttonTitle}
          color='black'
          fullWidth
          loading={loading || state.loading || uiLoading}
          disabled={loading || notAvailable}
          amount={product.quantityInCart}
          onClick={_handleOnCenterClick}
          onLeftIconClick={_handleOnRemove}
          onRightIconClick={_handleOnAdd} />
      </ContentContainer>
    </Container>
  )
}
