import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Heading, Paragraph, Link, Rule, Spacer, ResponsiveImage, Button } from '@atoms/index'
import { MealkitModal, MealkitModalEnum } from '@client/components/organisms/modals/MealkitModal'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ProductCategoriesContainer, ResponsiveProperty, ResponsivePXValue, SidePagePadding, theme, ZeroSpace } from '@components/Theme'
import { MealKitDishDetailsFragment, useUserCartQuery, useUserDetailsQuery, UserSubscriptionFragment, UserMenuFragment, MealKitTagFragment, UserCartDocument, useAddDishToUserMenuMutation, useAddItemToCartMutation, useRemoveDishFromUserMenuMutation, RegisteredUserDetailsFragment, UserDetailsFragment, MenuDishWineForMealKitDishFragment, WineListFragment, useGetAppQuery } from '@hooks/api'
import { CategoryTag, convertEnumToNum, FieldData, FloatingUpSell, Form, ProductCategories, SelectInput, SelectOption, useForm } from '@molecules/index'
import { ReactivateSubscriptionModal } from '@organisms/index'
import { UserSubscriptionStatusEnum, PortionSizeEnum, ProductAvailabilityEnum, UserStatusEnum, NumberOfPortionsEnum, ProductIngredient } from '@uctypes/api/globalTypes'

import { PairedWineModal } from '../modals/PairedWineModal'

import { DishInfoTable, DishInfoTableData } from './DishInfoTable'
import { useEvents } from '@contexts/GTMProvider'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const DishContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'stretch', tablet: 'stretch', desktop: 'flex-start' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '22px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: `0 ${theme.pagePadding.desktop}px` })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  ${ResponsiveProperty('padding', { mobile: '24px 16px 0 16px', tablet: '24px 16px 0 16px', desktop: '0 24px 0 0' })}

  .title-header {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '40px')}
  }

  .subtitle {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .text-description {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }
`

const ImageContainer = styled.div`
  
  ${ProductCategoriesContainer}

  .product-categories {
    ${ResponsivePXValue('max-height', { mobile: '65%' })}
  }

  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '100vw', tablet: '50vw', desktop: '576px' })}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
  ${ResponsivePXValue('min-width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

   .select-form { 
    display: flex;
    ${ResponsivePXValue('max-width', { mobile: 'initial', tablet: '65%', desktop: '300px' })}
    
    .dropdown{
      margin: 0;
      .select-input {
        ${ResponsivePXValue('padding-top', '2px')}
        ${ResponsivePXValue('padding-bottom', '2px')}
      }
    }
  }

  .action-button {
    ${ResponsivePXValue('max-width', { mobile: 'initial', tablet: '65%', desktop: '300px' })}
  }
`

const DishInfoTableContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const AllergenContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

const RuleContainer = styled.div`
  ${SidePagePadding}
  ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
`

const TagsContainer = styled.div`
  display: flex;
  align-self: stretch;
  overflow: auto;

  ${SidePagePadding}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'space-around' })};

  .tag {
    justify-content: flex-start;
    ${ResponsivePXValue('min-width', '116px')}

    img {
      ${ResponsivePXValue('height', '24px')}
      ${ResponsivePXValue('width', '24px')}
      ${ResponsivePXValue('min-width', '24px')}
    }
  }
`

export interface MealKitDishDetailsProps {
  dish: MealKitDishDetailsFragment
  allergens: string[]
  deliveryDate: string
  sluggedPortion: NumberOfPortionsEnum
  onNumberOfPortionsSelect?:(portionSize: NumberOfPortionsEnum) => void
}

interface MealKitDishDetailsState {
  pairingsOpen: boolean
  loading: boolean
  chosenDish: MealKitDishDetailsFragment | null
  activeModal: MealkitModalEnum
  displayReactivationModal: boolean
  portionSize: NumberOfPortionsEnum
  fullTime: number
  handsOnTime: number
  ingredientsPortioned: ProductIngredient
}

const DEFAULT_STATE: MealKitDishDetailsState = {
  pairingsOpen: false,
  loading: false,
  chosenDish: null,
  activeModal: MealkitModalEnum.NONE,
  displayReactivationModal: false,
  portionSize: NumberOfPortionsEnum.SERVES_2,
  fullTime: null,
  handsOnTime: null,
  ingredientsPortioned: null,
}

export const MealKitDishDetails = React.memo(({ dish, allergens, sluggedPortion, onNumberOfPortionsSelect }: MealKitDishDetailsProps): JSX.Element => {

  const config = useConfig()
  const [form] = useForm()
  const [state, setState] = useState<MealKitDishDetailsState>({ ...DEFAULT_STATE })
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addItemToCart] = useAddItemToCartMutation()
  const [addDishToBox] = useAddDishToUserMenuMutation()
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
  const finalAllergens = `${allergens.join(', ')}. Packed in a facility that handles all major food allergens and gluten, including Milk, Eggs, Fish, Shellfish, Tree Nuts, Peanuts, Wheat, Soybeans.`
  const navigate = useNavigate()
  const theme = useTheme()
  const events = useEvents()
  const { addToast } = useToasts()
  const hasSelectedPlan = userCartData?.currentUser?.checkoutStatus?.hasSelectedPlan
  const [removeItemFromCart] = useRemoveDishFromUserMenuMutation()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const category = dish?.mealKitCategories[0]
  const hasLunchCategory = !category?.canSubscribe

  const selectOptions: SelectOption[] = [
    {
      title: 'Serves 1',
      value: NumberOfPortionsEnum.SERVES_1,
    },
    {
      title: 'Serves 2',
      value: NumberOfPortionsEnum.SERVES_2,
      disabled: hasLunchCategory,
    },
    {
      title: 'Serves 3',
      value: NumberOfPortionsEnum.SERVES_3,
      disabled: hasLunchCategory,
    },
    {
      title: 'Serves 4',
      value: NumberOfPortionsEnum.SERVES_4,
      disabled: hasLunchCategory,
    },

  ]
  const buttonColor = 'black'
  const dishInfoTableData: DishInfoTableData[] = [{
    col1: {
      key: 'Eat Within',
      value: `${dish?.cookWithin}`,
    },
    col2: {
      key: 'Chef',
      value: dish?.chef?.name,
    },
  }, {
    col1: {
      key: 'Full Time',
      value: `${state.fullTime} mins`,
    },
    col2: {
      key: 'Active Time',
      value: `${state.handsOnTime} mins`,
    },
  }]

  const sessionSlugs = sessionStorage.getItem('SlugSessionString')
  const slugSessionObject :{ [k: string]: NumberOfPortionsEnum } = JSON.parse(sessionSlugs) || {}
  const sluggedPortions = slugSessionObject[dish?.slug]

  let defaultPortions:NumberOfPortionsEnum
  if (hasLunchCategory) {
    defaultPortions = NumberOfPortionsEnum.SERVES_1
  } else if (sluggedPortions) {
    defaultPortions = sluggedPortions
  } else if (appData.app.defaultPortions) {
    defaultPortions = appData.app.defaultPortions
  } else {
    defaultPortions = NumberOfPortionsEnum.SERVES_2
  }

  const _togglePairings = (open: boolean): void => {
    // GTMHelper.logWinePairingImpression(dish, 'Wine Pairing Modal')
    setState((prevState) => update(prevState, {
      pairingsOpen: { $set: open },
    }))
  }

  const _toggleLoading = (loading: boolean): void => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const servingSizeNumber = convertEnumToNum(state.portionSize)


  const _handleAddDishToBox = async (dish: MealKitDishDetailsFragment): Promise<void> => {
    if (!_handleDefaultAddressCheck()) return
    _toggleLoading(true)

    let portionSize = state.portionSize

    if (hasLunchCategory) {
      portionSize = NumberOfPortionsEnum.SERVES_1
    }

    try {
      await addDishToBox({
        variables: {
          input: {
            dishId: dish?.id,
            portionSize,
          },

        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      
      const logData = {
        itemName: dish?.name,
        itemId: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.id,
        price: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.price,
        itemBrand: 'UCOOK',
        itemCategory: dish?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: dish?.mealKitCategories.map((cat) => cat.title).join(', '),
        itemListName: 'Meal Kit',
        quantity: 1,
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        itemChef: dish?.chef?.name,
        isMealkit: 'yes',
        itemServingSize: servingSizeNumber,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasAddedToCart(snakedData)
      addToast('Item successfully added to your order. Hit \'save\' in your cart before you go, or this dish won’t be saved.', {
        appearance: 'warning',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _toggleLoading(false)
  }

  const _handleAddWineToCart = async (product: WineListFragment, quantity: number): Promise<void> => {
    _togglePairings(false)
    if (!_handleDefaultAddressCheck()) return
    if (quantity !== 0) {
      _toggleLoading(true)
      try {
        await addItemToCart({
          variables: { productId: product?.id, quantity },
          refetchQueries: [{ query: UserCartDocument }],
          awaitRefetchQueries: true,
        })

        addToast(`Successfully added (${quantity}) item to cart.`, {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      _toggleLoading(false)
    } else {
      addToast('Please select a valid quantity!', {
        appearance: 'error',
        autoDismiss: true,
      })
    }
  }

  const downloadFile = (href: string): void => {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.href = href
    a.download = 'file.pdf'
    a.target = '_blank'
    a.click()
    if (config.isBrowser()) {
      window.URL.revokeObjectURL(a.href)
    }
    document.body.removeChild(a)
  }

  const _handleDownloadClicked = (): void => {
    if (!subscription || subscription.status === UserSubscriptionStatusEnum.PENDING || subscription.status === UserSubscriptionStatusEnum.CANCELLED) {
      setState((prevState) => update(prevState, {
        activeModal: { $set: MealkitModalEnum.RECIPE_CARD },
      }))
    } else {
      const product = dish.products.find(product => { return product.portionSize === state.portionSize as unknown as PortionSizeEnum })
      if (product) {
        downloadFile(product.recipeCard.location)
      }
    }
  }

  const _handleShowDetails = (slug: string) => {
    navigate(`/wine/${slug}`)
  }

  const _handleServingSizeSelected = () => {
    setState((prevState) => update(prevState, {
      activeModal: { $set: MealkitModalEnum.NONE },
    }))
    if (state.chosenDish) {
      _handleAddDishToBox(state.chosenDish)
    }
  }

  const _handleOnRemove = async (): Promise<void> => {

    _toggleLoading(true)

    const cartData = userCartData?.currentUser?.activeMenu
    const getDishId = async (id: string, cartDishesArray: UserMenuFragment): Promise<string> => {
      for (let i = 0; i < cartDishesArray?.dishes?.length; i++) {
        if (cartDishesArray?.dishes[i].dish?.id === id) {
          return cartDishesArray?.dishes[i].id
        }
      }
    }
    const cartDishId = await getDishId(dish.id, cartData)

    try {

      if (dish.quantityInCart > 0) {
        await removeItemFromCart({
          variables: {
            userMenuDishId: cartDishId,
          },
          refetchQueries: SiteHelper.getUserRefetchQueries(),
          awaitRefetchQueries: true,
        })
      }
      let portionSize = state.portionSize

      if (hasLunchCategory) {
        portionSize = NumberOfPortionsEnum.SERVES_1
      }
      const logData = {
        itemName: dish?.name,
        itemId: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.id,
        price: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.price,
        itemBrand: 'UCOOK',
        itemCategory: dish?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: dish?.mealKitCategories.map((cat) => cat.title).join(', '),
        itemListName: 'Meal Kit',
        quantity: 1,
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        itemChef: dish?.chef?.name,
        isMealkit: 'yes',
        itemServingSize: servingSizeNumber,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasRemovedFromCart(snakedData)

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _toggleLoading(false)

  }

  const _handleShowAdd = (): void => {

    if (!hasAccount || !hasSelectedPlan || isGuestUser || isPaused) {
      navigate('/meal-kit/plans')
    } else if (isPaused) {
      _switchReactivateSubscriptionModal(true)

    } else {
      if (!_handleDefaultAddressCheck() || !_handleAvailabilityCheck()) return
      if (userCartData.currentUser.checkoutStatus.hasSelectedPortionSize) {
        _handleAddDishToBox(dish)
        setState((prevState) => update(prevState, {
          chosenDish: { $set: null },
        }))
      } else {
        setState((prevState) => update(prevState, {
          chosenDish: { $set: dish },
          activeModal: { $set: MealkitModalEnum.SERVING_SIZE },
        }))
      }
    }

  }

  const _handlePortionSizeChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'numberOfPortions') {

          const product = dish?.products.find(product => { return product.portionSize === field.value as unknown as PortionSizeEnum })
          const fullTime = product?.overallTime
          const handsOnTime = product?.handsOnTime
          const ingredientsForPortions = product?.ingredients

          setState((prevState) => update(prevState, {
            portionSize: { $set: field.value },
            fullTime: { $set: fullTime },
            handsOnTime: { $set: handsOnTime },
            ingredientsForPortions: { $set: ingredientsForPortions },
          }))

          const slug = dish?.slug
          slugSessionObject[slug] = field.value
          const slugSessionObjectString = JSON.stringify(slugSessionObject)
          sessionStorage.setItem('SlugSessionString', slugSessionObjectString)
          onNumberOfPortionsSelect(field.value)
        }
      })
    })
  }

  const _handleDefaultAddressCheck = (): boolean => {
    if (!userDetailsData?.currentUser?.hasDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
      return false
    } else {
      return true
    }
  }
  const _switchReactivateSubscriptionModal = (display: boolean): void => {
    setState((prevState) => update(prevState, {
      displayReactivationModal: { $set: display },
    }))
  }
  const _onReactivateSubscription = () => {
    _handleAddDishToBox(dish)
    _switchReactivateSubscriptionModal(false)
  }

  const _handleAvailabilityCheck = (): boolean => {
    let available = true
    for (let i = 0; i < dish.products.length; i++) {
      if (dish.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY) {
        available = false
        break
      }
    }
    if (!available) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
      return false
    } else {
      return true
    }
  }

  const _handleOnAdd = (): void => {

    _handleShowAdd()
  }

  const _handleOnCenterClick = async (): Promise<void> => {

    if (!hasAccount || !hasSelectedPlan || isGuestUser) {
      navigate('/meal-kit/plans')

    } else if (isPaused) {
      _switchReactivateSubscriptionModal(true)

    } else {
      _handleAddDishToBox(dish)
    }
  }
  const isGuestUser = userDetailsData?.currentUser?.status === UserStatusEnum.GUEST
  const hasAccount = userCartData?.currentUser?.checkoutStatus?.hasAccount
  const hasActiveSubscription = subscription?.status === UserSubscriptionStatusEnum.ACTIVE

  let isPaused = false

  if (registeredUser?.subscriptions?.length > 0 && registeredUser?.subscriptions[0]?.status === 'PAUSED') {
    isPaused = true
  }

  const dynamicButton = (): string => {
    let buttonText = 'ADD TO CART'
    if (!hasAccount || !hasSelectedPlan || isGuestUser || isPaused) {
      buttonText = 'GET STARTED'
    }
    return buttonText
  }

  const pairedWines = dish?.activeParings as MenuDishWineForMealKitDishFragment[] || []
  const numberDefaultPortions = convertEnumToNum(defaultPortions)

  let tag: MealKitTagFragment

  useEffect(() => {

    const defaultPortionSize = defaultPortions as unknown as PortionSizeEnum || PortionSizeEnum.SERVES_2
    const product = dish?.products.find(product => { return product.portionSize === defaultPortionSize })
    const fullTime = product?.overallTime
    const handsOnTime = product?.handsOnTime
    const ingredientsForPortions = product?.ingredients

    setState((prevState) => update(prevState, {
      fullTime: { $set: fullTime },
      handsOnTime: { $set: handsOnTime },
      ingredientsForPortions: { $set: ingredientsForPortions },
      portionSize: { $set: defaultPortions },
    }))
  }, [dish, userDetailsData])

  return (
    <Container>
      <ReactivateSubscriptionModal
        open={state.displayReactivationModal}
        onReactivation={_onReactivateSubscription}
        onClose={() => { _switchReactivateSubscriptionModal(false) }} />
      <PairedWineModal
        pairedWines={pairedWines}
        title='WINE PAIRINGS'
        open={state.pairingsOpen}
        onClose={() => _togglePairings(false)}
        onAddToCart={_handleAddWineToCart}
        onShowDetails={_handleShowDetails} />
      <MealkitModal
        activeModal={state.activeModal}
        onSubmit={_handleServingSizeSelected}
        onClose={() => { setState({ ...state, activeModal: MealkitModalEnum.NONE }) }}
        dish={dish} />
      <DishContainer>
        <ContentContainer>
          <Heading variant='h3' color={theme.colors.greys.liteCodGrey} className='title-header'>
            {dish?.name}
          </Heading>
          <Spacer universal='8px' />
          <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey} className='text-description'>
            {dish?.description}
          </Paragraph>
          <Spacer universal='16px' />
          <ButtonContainer>
            <Form className='select-form' form={form} onFieldsChange={_handlePortionSizeChange}>
              <SelectInput
                name='numberOfPortions'
                value={defaultPortions}
                readOnly={true}
                placeholder={`Serves ${numberDefaultPortions}`}
                itemsToDisplay={4}
                options={selectOptions}
                rules={[{ required: true }]}
                showLabel={false}
                className='dropdown' />
            </Form>
            <Spacer universal='16px' />
            <Button
              className='action-button'
              loading={state.loading}
              color={buttonColor}
              fluid
              title={dynamicButton()}
              amount={dish?.quantityInCart}
              onClick={_handleOnCenterClick}
              onLeftIconClick={_handleOnRemove}
              onRightIconClick={_handleOnAdd} />
          </ButtonContainer>
          <Spacer universal='24px' />
          <DishInfoTableContainer>
            <DishInfoTable data={dishInfoTableData} />
          </DishInfoTableContainer>
          <AllergenContainer>
            <Paragraph variant='p2' color={theme.colors.slates.bitter}>
              Allergens: {finalAllergens}
            </Paragraph>
          </AllergenContainer>
          <If condition={hasActiveSubscription}>
            <Link
              color={theme.colors.oranges.coral}
              variant='l2'
              bold
              href='\'
              onClick={_handleDownloadClicked} >
              DOWNLOAD THE RECIPE
            </Link>
          </If>
        </ContentContainer>
        <ImageContainer id='mealKitDetailsImageContainer'>
          <If condition={dish?.upSellText?.length > 0}>
            <FloatingUpSell text={dish.upSellText} />
          </If>
          <ProductCategories
            product={dish}
            iconSize='large'
            showCategories={false} />
          <ResponsiveImage image={dish?.coverImage} />
        </ImageContainer>
      </DishContainer>
      <RuleContainer>
        <Rule color='slate' />
      </RuleContainer>
      <TagsContainer>
        <For each='tag' of={dish?.tags || []}>
          <CategoryTag
            image={tag.icon.desktop}
            title={tag?.title} key={tag.id}
            showBackground={false}
            className='tag' />
        </For>
      </TagsContainer>
    </Container >
  )

})

MealKitDishDetails.displayName = 'MealKitDishDetails'
