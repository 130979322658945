import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { Heading, Rule, Paragraph, Spacer, Button } from '@atoms/index'
import { MultipleImageContainer, ProductImageInterface } from '@client/components/atoms/images/MulitpleImageContainer'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { ResponsiveProperty, ResponsivePXValue, SidePagePadding, theme, ZeroSpace } from '@components/Theme'
import { UserCartDocument, MealKitDishListFragment, WineDetailsFragment, WineTagFragment, useAddDishToUserMenuMutation, useAddItemToCartMutation, useChangeCartItemQuantityMutation, useGetAppQuery, useRemoveItemFromCartMutation, useUserCartQuery, useUserDetailsQuery } from '@hooks/api'
import { CategoryTag, WineDiscount, convertEnumToNum, FieldData, Form, SelectInput, SelectOption, useForm, FloatingUpSell } from '@molecules/index'
import { DeviceTypeEnum, NumberOfPortionsEnum, ProductAvailabilityEnum, ProductStockStatusEnum } from '@uctypes/api/globalTypes'

import { LowStockContainer } from '../misc'
import { PairingsModal } from '../modals/PairingsModal'

import { DishInfoTable, DishInfoTableData } from './DishInfoTable'
import { useEvents } from '@contexts/GTMProvider'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  ${ResponsivePXValue('border-radius', { mobile: '0', tablet: '0', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '100vw', tablet: '50vw', desktop: '576px' })}
  ${ResponsivePXValue('width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
  ${ResponsivePXValue('min-width', { mobile: '100vw', tablet: '100vw', desktop: '576px' })}
`

const WineContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column-reverse', tablet: 'column-reverse', desktop: 'row' })}
  ${ResponsiveProperty('align-items', { mobile: 'stretch', tablet: 'stretch', desktop: 'flex-start' })}
  ${ResponsivePXValue('margin-bottom', { mobile: '22px', tablet: '24px', desktop: '32px' })}
  ${ResponsivePXValue('padding', { mobile: '0', tablet: '0', desktop: `0 ${theme.pagePadding.desktop}px` })}
`

const CategoryContainer = styled.div`
  ${ResponsiveProperty('display', { mobile: 'none', tablet: 'none', desktop: 'flex' })}
`

const ContentContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  ${ResponsiveProperty('padding', { mobile: '24px 16px 0 16px', tablet: '24px 16px 0 16px', desktop: '0 24px 0 0' })}

  .header-title {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '40px')}
  }

 .header-subtitle {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }

  .content-text-description {
    ${ZeroSpace}
    ${ResponsivePXValue('line-height', '24px')}
  }
`

const RuleContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
  ${SidePagePadding}
`

const TagsContainer = styled.div`
  display: flex;
  align-self: stretch;
  overflow: auto;

  ${SidePagePadding}
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'space-between', desktop: 'space-around' })};

  .tag {
    justify-content: flex-start;
    ${ResponsivePXValue('min-width', '116px')}

    img {
      ${ResponsivePXValue('height', '24px')}
      ${ResponsivePXValue('width', '24px')}
      ${ResponsivePXValue('min-width', '24px')}
    }
  }
`

const CostContainer = styled.div`
  .rand {
    ${ZeroSpace}
    line-height: 1;
  }
`

const WineInfoTableContainer = styled.div`
  ${ResponsivePXValue('margin-bottom', { mobile: '16px', tablet: '20px', desktop: '24px' })}
`

export interface WineBottleDetailsProps {
  wine: WineDetailsFragment
}

interface WineBottleDetailsState {
  quantity: number
  pairingsOpen: boolean
  loading: boolean
  outOfStock: boolean
  currentAmountInCart: number
  portionSize: NumberOfPortionsEnum
}

const DEFAULT_STATE: WineBottleDetailsState = {
  quantity: 1,
  pairingsOpen: false,
  loading: false,
  outOfStock: false,
  currentAmountInCart: null,
  portionSize: NumberOfPortionsEnum.SERVES_2,
}

export const WineBottleDetails = React.memo(({ wine }: WineBottleDetailsProps): JSX.Element => {

  const config = useConfig()
  const [form] = useForm()
  const [state, setState] = useState<WineBottleDetailsState>({ ...DEFAULT_STATE })
  const [addItemToCart] = useAddItemToCartMutation()
  const [addDishToBox] = useAddDishToUserMenuMutation()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const theme = useTheme()
  const navigate = useNavigate()
  const events = useEvents()
  const { addToast } = useToasts()
  const wineImages = wine?.productImages as unknown as ProductImageInterface[]
  const { data: userCartData, refetch } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation({
    refetchQueries: SiteHelper.getUserRefetchQueries(),
    awaitRefetchQueries: true,
  })
  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const cartItems = userCartData?.currentUser?.activeCart?.cartItems
  const defaultPortions = appData.app.defaultPortions ? appData.app.defaultPortions: NumberOfPortionsEnum.SERVES_2

  const selectOptions: SelectOption[] = [
    {
      title: '1',
      value: NumberOfPortionsEnum.SERVES_1,
    },
    {
      title: '2',
      value: NumberOfPortionsEnum.SERVES_2,
    },
    {
      title: '3',
      value: NumberOfPortionsEnum.SERVES_3,
    },
    {
      title: '4',
      value: NumberOfPortionsEnum.SERVES_4,
    },

  ]

  const numberDefaultPortions = convertEnumToNum(defaultPortions)
  const dishInfoTableData: DishInfoTableData[] = [{
    col1: {
      key: 'Variety',
      value: `${wine?.wineCategory.title}`,
    },
    col2: {
      key: 'Vintage',
      value: `${wine?.vintage}`,
    },
  }, {
    col1: {
      key: 'Sugar',
      value: `${wine?.sugar}g`,
    },
    col2: {
      key: 'Alcohol',
      value: `${wine?.alcoholContent}%`,
    },
  }]

  const _handlePortionSizeChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'numberOfPortions') {
          setState((prevState) => update(prevState, {
            portionSize: { $set: field.value },
          }))
        }
      })
    })
  }

  const _toggleLoading = (loading: boolean) => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const _togglePairingsModal = (open: boolean): void => {
    setState((prevState) => update(prevState, {
      pairingsOpen: { $set: open },
    }))
  }
  const servingSizeNumber = convertEnumToNum(state.portionSize)

  const _handleAddDishToBox = async (dish: MealKitDishListFragment): Promise<void> => {
    _togglePairingsModal(false)
    if (!_handleDefaultAddressCheck()) return
    _toggleLoading(true)
    try {
      await addDishToBox({
        variables: {
          input: {
            dishId: dish?.id,
            portionSize: state.portionSize,
          },

        },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })

      const logData = {
        itemName: dish?.name,
        itemId: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === defaultPortions)?.id,
        price: dish?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === defaultPortions)?.price,
        itemBrand: 'UCOOK',
        itemCategory: dish?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
        itemVariant: dish?.mealKitCategories.map((cat) => cat.title).join(', '),
        itemListName: 'Meal Kit',
        quantity: 1,
        itemImage: dish?.coverImage?.location,
        itemStockCount: 1,
        itemChef: dish?.chef?.name,
        isMealkit: 'yes',
        itemServingSize: servingSizeNumber,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasAddedToCart(snakedData)
      addToast('Item successfully added to your order. Hit \'save\' in your cart before you go, or this dish won’t be saved.', {
        appearance: 'warning',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _toggleLoading(false)
  }

  const _handleAddToCart = async (): Promise<void> => {
    if (!_handleDefaultAddressCheck() || !_handleAvailabilityCheck() || state.quantity === 0) return
    _toggleLoading(true)
    try {
      await addItemToCart({
        variables: { productId: wine?.id, quantity: state.quantity },
        refetchQueries: [{ query: UserCartDocument }],
        awaitRefetchQueries: true,
      })
      const logData = {
        itemName: wine?.name,
        itemId: wine?.id,
        price: wine?.price,
        itemBrand: wine?.vineyard?.name,
        itemCategory: 'Wines',
        itemVariant: wine?.wineCategory?.title,
        itemListVariant: 'Wines',
        quantity: state.quantity,
        itemImage: wine?.coverImage?.location,
        itemStockCount: 1,
        itemChef: wine?.vineyard?.name,
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasAddedToCart(snakedData)

      addToast(`Successfully added (${state.quantity}) item to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _toggleLoading(false)
  }

  const _handleShowDetails = (slug: string) => {
    navigate(`/meal-kit/${slug}`)
  }

  const _handleDefaultAddressCheck = (): boolean => {
    if (!userDetailsData?.currentUser?.hasDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
      return false
    } else {
      return true
    }
  }

  const _handleAvailabilityCheck = (): boolean => {
    if (wine.availability !== ProductAvailabilityEnum.AVAILABLE) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
      return false
    } else {
      return true
    }
  }

  const isOutOfStock = (): boolean => {
    return wine.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK
  }

  useEffect(() => {
    if (wine) {
      setState((prevState) => update(prevState, {
        outOfStock: { $set: isOutOfStock() },
      }))
    }
  }, [wine])

  let tag: WineTagFragment
  //

  const _handleOnCenterClick = async (): Promise<void> => {
    if (!(state.currentAmountInCart > 0)) {
      _handleAddToCart()
    }
  }

  const _handleOnRemove = async (): Promise<void> => {

    const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

    setState((prevState) => update(prevState, { addToCartInterrupted: { $set: noDefaultAddress } }))

    if (noDefaultAddress) {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    } else {

      try {
        setState((prevState) => update(prevState, { loading: { $set: true } }))

        if (state.currentAmountInCart === 1) {

          await removeItemFromCart({
            variables: {
              productId: wine.id,
            },
            refetchQueries: SiteHelper.getUserRefetchQueries(),
            awaitRefetchQueries: true,
          })
          setState((prevState) => update(prevState, { currentAmountInCart: { $set: 0 } }))
        } else {
          await changeCartItemQuantity({
            variables: {
              productId: wine.id,
              quantity: state.currentAmountInCart - 1,
            },
          })
        }
        const logData = {
          itemName: wine?.name,
          itemId: wine?.id,
          price: wine?.price,
          itemBrand: wine?.vineyard?.name,
          itemCategory: 'Wines',
          itemVariant: wine?.wineCategory?.title,
          itemListVariant: 'Wines',
          quantity: state.quantity,
          itemImage: wine?.coverImage?.location,
          itemStockCount: 1,
          itemChef: wine?.vineyard?.name,
          isMealkit: 'no',
          itemServingSize: 1,
        }
  
        const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
        events.hasRemovedFromCart(snakedData)
        

        addToast('Item successfully removed from cart', {
          appearance: 'success',
          autoDismiss: true,
        })
      } catch (e) {
        addToast(e.message, {
          appearance: 'error',
          autoDismiss: true,
        })
      }
      setState((prevState) => update(prevState, { loading: { $set: false } }))

    }
  }

  const _handleOnAdd = (): void => {
    _handleAddToCart()
  }

  const isCardItemInBasket = () => {

    const quantity = cartItems?.find((item) => item?.product?.id === wine?.id)?.quantity || 0

    setState((prevState) => update(prevState, {
      currentAmountInCart: { $set: quantity },
    }))
  }

  useEffect(() => {
    refetch()
    isCardItemInBasket()
  }, [userCartData])

  return (
    <Container>
      <PairingsModal
        pairedMeals={wine?.activeParings}
        title='MEAL PAIRINGS'
        open={state.pairingsOpen}
        onClose={() => _togglePairingsModal(false)}
        onAddToCart={_handleAddDishToBox}
        onShowDetails={_handleShowDetails}
      />
      <WineDiscount />
      <WineContainer>
        <ContentContainer>
          <CategoryContainer>
            <CategoryTag image={wine?.wineCategory?.icon?.desktop} title={wine?.wineCategory?.title} />
          </CategoryContainer>
          <Spacer universal='16px' />
          <Heading variant='h3' color={theme.colors.greys.liteCodGrey} className='header-title'>
            {wine?.name}
          </Heading>
          <Spacer universal='8px' />
          <Paragraph variant='p1' color={theme.colors.greys.darkCodGrey} className='content-text-description'>
            {wine?.description}
          </Paragraph>
          <Spacer universal='16px' />
          <LowStockContainer
            displayRule
            product={wine}
            flexDirection={isMobile ? 'row' : 'column'}
            alignItems={isMobile ? 'center' : 'flex-start'}>
            <CostContainer>
              <Heading className='rand' variant='h3' color={theme.colors.greys.liteCodGrey}>
                {`R${wine?.price}`}
              </Heading>
            </CostContainer>
          </LowStockContainer>
          <Spacer universal='16px' />
          <Form className='flex-form' form={form} onFieldsChange={_handlePortionSizeChange}>
            <SelectInput
              name='numberOfPortions'
              value={defaultPortions}
              placeholder={String(numberDefaultPortions)}
              itemsToDisplay={4}
              options={selectOptions}
              rules={[{ required: true }]}
              className='dropdown' />
          </Form>
          <Spacer universal='16px' />
          <Button
            className='add-button'
            title={state.outOfStock ? 'OUT OF STOCK' : 'ADD TO CART'}
            color='black'
            fullWidth
            loading={state.loading}
            disabled={state.loading}
            amount={state.currentAmountInCart}
            onClick={_handleOnCenterClick}
            onLeftIconClick={_handleOnRemove}
            onRightIconClick={_handleOnAdd} />
          <Spacer universal='24px' />
          <WineInfoTableContainer>
            <DishInfoTable data={dishInfoTableData} />
          </WineInfoTableContainer>
        </ContentContainer>
        <ImageContainer>
          <If condition={wine?.upSellText?.length > 0}>
            <FloatingUpSell text={wine.upSellText} />
          </If>
          <MultipleImageContainer images={wineImages}/>
        </ImageContainer>
      </WineContainer>
      <RuleContainer>
        <Rule color='slate' />
      </RuleContainer>
      <TagsContainer>
        <For each='tag' of={wine?.tags || []}>
          <CategoryTag
            image={tag.icon.desktop}
            title={tag?.title} key={tag.id}
            showBackground={false}
            className='tag' />
        </For>
      </TagsContainer>
    </Container>
  )
})

WineBottleDetails.displayName = 'WineBottleDetails'
