import { AddToCartEventInterface, CheckoutInterface, EventInterface, GenerateLeadInterface, ItemListInterface, PaymentInfoInterface, PurchaseInterface, RemoveFromCartInterface, SelectItemInterface, ShippingInfoInterface, TagInterface, TagManager, UserIdInterface, ViewItemInterface } from 'sih-gtm/build/TagManager'

export interface LoginInfoInterface extends EventInterface {
  event: 'login',
  eventParams: {
    userId: string,
    method: string,
    source: string
  },
  

}

export interface SignupInfoInterface extends EventInterface {
  event: 'sign_up',
  eventParams: {
    userId: string,
    method: string,
    newsletter: string,
    source: string,
  },

}

export interface CouponDetailsInterface extends EventInterface {
  event: 'coupon-insert',
  eventParams: {
    couponName: string,
    couponType: string,
  },
  
}

export interface SearchItemInterface extends EventInterface {
  event: 'search',
  eventParams: {
    term: string,
  },
  
}
export class GTMBase {

  eventPrefix: string
  currency: string

  constructor(key: string, eventPrefix = '', currency = 'ZAR') {
    this.eventPrefix = eventPrefix
    this.currency = currency
    

    const tagManagerArgs: TagInterface = {
      id: key,
      auth: 'wq_Vs41rXxSAk53yLfgWlQ',
      preview: 'env-1',
      domain: 'https://www.googletagmanager.com',
      eventData: undefined,
    }
    
    TagManager.initialize(tagManagerArgs)
  }
 
 

  // protected pageView(page: GAPageView): void {
  //   TagManager.pushEvent({
  //     event: 'pageview',
  //     page,
  //   })
  // }

  protected addPaymentInfo(eventData: PaymentInfoInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected addUserInfo(eventData: UserIdInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected addShippingInfo(eventData: ShippingInfoInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected addToCart(eventData: AddToCartEventInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected beginCheckout(eventData: CheckoutInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected login(eventData: LoginInfoInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected generateLead(eventData: GenerateLeadInterface): void {
    TagManager.pushEvent(eventData)
  }
  protected purchase(eventData: PurchaseInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected removeFromCart(eventData: RemoveFromCartInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected search(eventData: SearchItemInterface): void {
    TagManager.pushEvent(eventData)
  }

  // protected selectContent(params: GASelectContent): void {
  //   TagManager.pushEvent({
  //     event: 'select_content',
  //     params,
  //   })
  // }

  protected selectItem(eventData: SelectItemInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected signUp(eventData: SignupInfoInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected viewItem(eventData: ViewItemInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected viewItemList(eventData: ItemListInterface): void {
    TagManager.pushEvent(eventData)
  }

  protected addCoupon(eventData: CouponDetailsInterface): void {
    TagManager.pushEvent(eventData)
  }

  
}
