import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled, { CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { Heading, Icon, IconSizeEnum, IconEnum, Spacer, Loader } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useLoadingData } from '@client/hooks/UseLoadingData'
import { useOnScreen } from '@client/hooks/UseOnScreen'
import { useUserDetailsQuery, useGetAppQuery, useMyShopFrozenItemsQuery, MyShopFrozenItemsQuery, FrozenMealDishListFragment } from '@hooks/api'
import { MiniProductGrid, NavLinks, SectionLoading, StoreStickyButton } from '@molecules/index'
import { MarketProductFilters, DeviceTypeEnum, ProductRangeEnum, FrozenPortionSizeEnum } from '@uctypes/api/globalTypes'
import { SearchEngineOptimization } from '@utility/SearchEngineOptimization'

import { FrozenMyShopCard } from '../molecules/stores/FrozenMyShopCard'
import { ResponsivePXValue, ResponsiveProperty, LoadEffect } from '../Theme'
import { DeviceContainer } from '../utility'

import { getFrozenNavLinks } from './FrozenAisles'
import { useEvents } from '@contexts/GTMProvider'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'

const seo = {
  name: 'UCOOK Craft Meal Bought Before',
  title: 'Buy Craft Meal Online in South Africa | UCOOK Craft Meal',
  meta: [{
    name: 'description',
    content: 'Our markets are sourced from SA\'s best farms, and specially paired with each Meal Kit recipe so that you can enjoy a special dining experience at home.',
  }, {
    name: 'keywords',
    content: 'Buy Market Online,Market Online,Order Market Online',
  }, {
    name: 'robots',
    content: 'index,follow',
  }],
}
const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${ResponsivePXValue('padding-top', { mobile: '16px', desktop: '32px' })}
  ${ResponsivePXValue('width', { mobile: '100%', desktop: '1136px' }, { desktop: true })}


  .button-container {
    margin: 0;
  }
`
const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

 ${ResponsivePXValue('padding', { mobile: '0 16px', tablet: '0 16px' })}
`

const HeadingContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  ${ResponsiveProperty('justify-content', { mobile: 'space-between', tablet: 'flex-start', desktop: 'flex-start' })}
  ${ResponsivePXValue('height', { mobile: '44px', desktop: '64px' })}
`
const Grid = styled.div <{ $isNativeApplication: boolean, $isLoading: boolean }>`
  display: flex;
  gap: 16px;

  ${(props): CSS => props.$isLoading ? LoadEffect : undefined};

  ${ResponsiveProperty('flex-direction', { mobile: 'column', desktop: 'row' })}

  .craft-meal-filter { 
    ${ResponsivePXValue('padding', { mobile: '8px 0', tablet: '0 16px' })}
    ${ResponsivePXValue('width', { desktop: '272px' })}

    .filter-pill-container {
       ${ResponsivePXValue('left', { mobile: '36px' })}
    }
  }
`
const MoreContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('width', { mobile: 'CALC(100$ - 64px)', desktop: 'CALC(100% - 64px)' })}
 
`
const DishContainer = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  gap: 16px;
  height: fit-content;
  ${ResponsivePXValue('justify-content', { mobile: 'center' })}

  .craft-meal-card {
    margin: 0;
  }
`

const IconContainer = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`

interface FrozenMyShopStoreState {
  filters: MarketProductFilters
  skip: number
  limit: number
  displayEducationalBanner: boolean
  fetchMoreLoading: boolean
  hasLoggedImpressions: boolean
}

const DEFAULT_STATE: FrozenMyShopStoreState = {
  filters: {},
  skip: 0,
  limit: 30,
  displayEducationalBanner: false,
  fetchMoreLoading: false,
  hasLoggedImpressions: false,
}

export function CraftMealMyShopStore(): JSX.Element {

  const [state, setState] = useState<FrozenMyShopStoreState>({ ...DEFAULT_STATE })
  const config = useConfig()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const events = useEvents()
  const isDesktop = appData.app.deviceType === DeviceTypeEnum.DESKTOP || appData.app.deviceType === DeviceTypeEnum.ULTRA
  const isMobile = appData.app.deviceType === DeviceTypeEnum.MOBILE

  const { data: myShopFrozenData, loading: myShopLoading, fetchMore, refetch } = useMyShopFrozenItemsQuery({
    variables: {
      limit: state.limit,
      skip: state.skip,
      userId: userDetailsData?.currentUser?.id,
      input: {
        productType: ProductRangeEnum.FROZEN_MEAL,
      },
    },
    skip: !userDetailsData?.currentUser,

  })

  const myShopFrozenList = useLoadingData<Readonly<MyShopFrozenItemsQuery['myShopItems']['list']>>({
    data: myShopFrozenData?.myShopItems?.list,
    defaultData: [],
    loading: myShopLoading,
  })

  const hasMyShopFrozenItems = myShopFrozenList?.length>0
  const loadedListLengthMyShop = myShopFrozenData?.myShopItems?.list?.length
  const totalProductsInSection = myShopFrozenData?.myShopItems?.count
  const isDoneLoading = state.skip >= totalProductsInSection
  const hasMore = myShopFrozenData?.myShopItems?.count > state.skip + state.limit

  let myShopFrozenListReady = true
  if (myShopFrozenList.length && myShopFrozenList[0]?.__typename !== 'FrozenMeal') {
    myShopFrozenListReady = false
    refetch()
  }
  const itemsOrdered: any[] = []

  if (myShopFrozenListReady) {
    for (let i = 0; i < myShopFrozenList?.length; i++) {
      itemsOrdered.push(myShopFrozenList[i].group)
    }
  }

  const _handleBannerToggle = (): void => {
    setState((prevState) => update(prevState, {
      displayEducationalBanner: { $set: !prevState.displayEducationalBanner },
    }))
  }
  const _handleFetchMore = (): void => {

    if (loadedListLengthMyShop === state.skip + state.limit) {
      setState((prevState) => update(prevState, {
        skip: { $set: prevState.skip + prevState.limit },
        fetchMoreLoading: { $set: false },
      }))
    }

  }

  const performFetchMore = async (): Promise<void> => {

    fetchMore({
      variables: {
        skip: state.skip,
      },
    })

  }
  const divRefs = useOnScreen(_handleFetchMore)
  const renderMiniCardGrid = () => {
    const batchSize = 4
    const miniProductGrids = []

    for (let i = 0; i < itemsOrdered.length; i += batchSize) {
      const batch = itemsOrdered.slice(i, i + batchSize)
      miniProductGrids.push(
        <MiniProductGrid key={`batch${i / batchSize + 1}`} meals={batch} isMixed/>,
      )
    }

    return miniProductGrids
  }
  // Analytics
  useEffect(() => {
    if (!myShopLoading && !!hasMyShopFrozenItems && !state.hasLoggedImpressions) {
      events.hasViewedCatalogue(
        myShopFrozenList.map((meal, displayIndex) => {

          const logData = {
            itemName: meal?.name,
            itemId: meal?.id,
            price: meal?.price,
            itemBrand: 'UCOOK',
            itemCategory: 'Craft Meals Bought Before',
            itemVariant: 'BoughtBefore',
            itemListName: 'Craft Meals Bought Before',
            index: displayIndex,
            itemImage: meal?.coverImage?.location,
          }
          const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

          return snakedData
        }),
        userDetailsData?.currentUser?.id)
      setState((prevState) => update(prevState, {
        hasLoggedImpression: { $set: true },
      }))
    }
  }, [myShopFrozenList])

  useEffect(() => {
    performFetchMore()
  }, [state.skip])

  const boughtBeforeLink = [{
    title: 'Bought Before',
    url: '/frozen/bought-before',
    isActive: true,
  }]
  const navLinks = getFrozenNavLinks({
    additionalLinks: boughtBeforeLink,
  })

  let craftMeal: FrozenMealDishListFragment

  return (
    <Container>
      <SearchEngineOptimization seo={seo} />
      <Choose>
        <When condition={myShopLoading && !hasMyShopFrozenItems && !myShopFrozenListReady && itemsOrdered.length>0}>
          <SectionLoading height='100vh' />
        </When>
        <Otherwise>
          <StoreStickyButton className='button-container' isMobile={isMobile} />
          <TopContainer>
            <HeadingContainer>
              <Heading variant='h1'>Bought Before</Heading>
              <Spacer desktop='8px' variant='horizontal' />
              <IconContainer onClick={_handleBannerToggle}>
                <Icon icon={IconEnum.INFORMATION_CIRCLE} size={IconSizeEnum.SMALL} />
              </IconContainer>
            </HeadingContainer>
            <DeviceContainer $desktop>
              <NavLinks navLinks={navLinks} outline />
            </DeviceContainer>
            <Spacer desktop='24px' />
          </TopContainer>
          <Grid $isNativeApplication={isNativeApplication} $isLoading={myShopLoading}>
            <Spacer mobile='4px' />
            <DishContainer id='productGrid'>
              <Choose>
                <When condition={isDesktop}>
                  <For each='craftMeal' of={itemsOrdered || []}>
                    <FrozenMyShopCard
                      className='craft-meal-card'
                      key={craftMeal.id}
                      loading={myShopLoading}
                      craftMeal={craftMeal} />
                  </For>
                </When>
                <Otherwise>
                  {renderMiniCardGrid()}
                </Otherwise>
              </Choose>

              <If condition={!isDoneLoading && hasMore}>
                <MoreContainer ref = {divRefs}>
                  <Loader noShadow={true}></Loader>
                </MoreContainer>
              </If>

            </DishContainer>
          </Grid>
          <Spacer universal='24px' />
        </Otherwise>
      </Choose>
    </Container>
  )
}
