import React, { useEffect } from 'react'
import { useNavigate } from 'react-router'
import styled, { CSS } from 'styled-components'
import { Button, IconEnum, Spacer } from '@atoms/index'
import { useConfig } from '@client/contexts/ConfigProvider'
import { PaddedSectionContainer, ResponsivePXValue, SidePagePadding, theme } from '@components/Theme'
import { WineDetailsFragment, useUserDetailsQuery } from '@hooks/api'
import { WineBottleDetails, WineBottleDetailsProps, WineYouMightAlsoLike, WineYouMightAlsoLikeProps } from '@organisms/index'
import { useEvents } from '@contexts/GTMProvider'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'

const BackButtonContainer = styled.div`
 display: flex;
 ${SidePagePadding}
 ${ResponsivePXValue('margin-bottom', { mobile: '24px', tablet: '28px', desktop: '36px' })}
`
const GhostButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  ${ResponsivePXValue('margin', { mobile: '24px 0', tablet: '28px 0', desktop: '36px 0' })}
  ${ResponsivePXValue('padding-bottom', { mobile: '16px' })}
`
const SectionContainer = styled.div<{ $backgroundColor?: string, $customBackground?: string, $padded: boolean }>`
  ${(props): CSS => PaddedSectionContainer(props.$backgroundColor, props.$customBackground, props.$padded)};
`

export interface WinePDPProps {
  dish: WineDetailsFragment
  loading: boolean
}

export function WinePDP({ dish, loading }: WinePDPProps): JSX.Element {

  const navigate = useNavigate()
  const events = useEvents()
  const wine = dish
  const config = useConfig()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const wineDetailsData: WineBottleDetailsProps = {
    wine,
  }

  const details: string[] = []
  for (const detail of wine?.details || []) {
    details.push(detail)
  }

  const wineYouMightAlsoLikeProps: WineYouMightAlsoLikeProps = {
    wine,
  }

  const _handleBackClicked = (): void => {
    navigate(-1)
  }

  useEffect(() => {
    if (!loading) {

      const logData = {
        itemMame: wine?.name,
        itemId: wine?.id,
        price: wine?.price,
        itemBrand: wine?.vineyard?.name,
        itemCategory: wine?.wineCategory?.id,
        itemVariant: wine?.wineCategory?.title,
        itemListName: 'Wines',
        itemImage: wine?.coverImage?.location,
        itemStockCount: wine?.stockCount > 1 ? 1 : 0,
        isMealkit: 'no',
        itemChef: wine?.vineyard?.name,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

      events.hasViewedProduct('Wines', snakedData, userDetailsData?.currentUser?.id)
      events.hasSelectedItem('Wines', snakedData, userDetailsData?.currentUser?.id)
    }
  }, [loading])

  return (
    <>
      <SectionContainer $padded={false}>
        <BackButtonContainer>
          <Button
            title='BACK'
            variant='secondary'
            displayBorder={false}
            color='grey'
            leftIcon={IconEnum.CHEVRON_BACK_OUTLINE}
            iconColor={theme.colors.oranges.coral}
            loading={loading}
            onClick={_handleBackClicked} />
        </BackButtonContainer>
        <Spacer universal='16px' />
        <WineBottleDetails {...wineDetailsData} />
        <Spacer universal='16px' />
      </SectionContainer>
      {/* <SectionContainer backgroundColor='grey' padded>
        <MoreAboutWine {...moreAboutDishData} />
      </SectionContainer> */}
      <SectionContainer $padded $backgroundColor='grey'>
        <WineYouMightAlsoLike {...wineYouMightAlsoLikeProps} />
        <GhostButtonContainer >
          <Button variant='secondary' title='BACK TO THE MENU' color='grey' onClick={_handleBackClicked} />
        </GhostButtonContainer>
      </SectionContainer>
    </>
  )
}
