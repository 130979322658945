import React, { useEffect, useState } from 'react'

import { addSeconds } from 'date-fns'
import { JwtPayload, jwtDecode } from 'jwt-decode'
import update from 'react-addons-update'
import OneSignalReact from 'react-onesignal'
import styled from 'styled-components'

import { AppPlugin, APP_DEFAULT_STATE } from '@api/local'
import { Spacer, Paragraph, Button, Link } from '@client/components/atoms'
import { TitleSeperator } from '@client/components/atoms/layout/TitleSeperator'
import { ResponsiveProperty, ResponsivePXValue, theme, ZeroSpace } from '@client/components/Theme'
import { DeviceContainer } from '@client/components/utility'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { RegisteredUserDetailsFragment, useGetAppQuery, UserDetailsDocument, UserDetailsFragment, useRegisterCommunicationPlatformMutation, useRequestPhoneVerificationMutation, useUserDetailsQuery, useUserSignUpMutation, useUserSocialSignUpMutation } from '@hooks/api'
import { CheckBox, FacebookSocialButton, FieldData, Form, GoogleIdentitySocialButton, ModalMessageContainer, TextInput, useForm } from '@molecules/index'
import { UserCommunicationPlatformEnum, SocialPlatformEnum, SocialLoginInput, UserSignUpInput } from '@uctypes/api/globalTypes'

import { oneSignalAppInfoInterface } from '.'
import { useEvents } from '@contexts/GTMProvider'

const Container = styled.div<{$isNativeApplication: boolean}>`
  ${ResponsivePXValue('padding', '0 12px')}

  .input {
    ${ZeroSpace}
  }

  .check-input {
    div {
      font-family: gordita;
      font-weight: 400;
      ${ResponsivePXValue('font-size', { mobile: '8px', desktop: '16px' })}
      ${ResponsivePXValue('line-height', '16px')}
    }
  }
`

const NameContainer = styled.div`
  width: 100%;
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', tablet: 'row', desktop: 'row' })}

  .name-input {
    ${ZeroSpace}
    flex-grow: 1;
  }
`

const NameSpacer = styled.div`
  ${ResponsivePXValue('width', { mobile: '0', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '16px', tablet: '0', desktop: '0' })}
`
const FooterContainer = styled.div`
  display: flex;
  justify-content: center;
`
const TextLink = styled.a`
  color: ${theme.colors.slates.bitter};

  ${ResponsivePXValue('padding', '0 5px')}
`

const SocialButtonContainer = styled.div`
  display: flex;
  ${ResponsiveProperty('flex-direction', { mobile: 'column', desktop: 'row' })}
`
interface SignupState {
  communicationConsent: boolean
  error: string
  hasGeneratedLead: boolean
}

const DEFAULT_STATE: SignupState = {
  communicationConsent: true,
  error: '',
  hasGeneratedLead: false,
}
export interface SignupFormProps {
  onSignedUp: (hasCellphoneNumber: boolean, cellphoneIsVerified: boolean) => void
  onLogIn: () => void
}

export function SignupForm({ onSignedUp, onLogIn }: SignupFormProps): JSX.Element {

  const config = useConfig()
  const [state, setState] = useState<SignupState>({ ...DEFAULT_STATE })
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const isNativeApplication = appData.app.isNativeApplication
  const [signup, { loading: signupLoading }] = useUserSignUpMutation()
  const [addOneSignalData] = useRegisterCommunicationPlatformMutation()
  const [socialSignup, { loading: socialSignupLoading }] = useUserSocialSignUpMutation()
  const [requestPhoneVerification, { loading: requestPhoneVerificationLoading }] = useRequestPhoneVerificationMutation()
  const { data: userDetailsData, loading: userDetailsLoading } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [form] = useForm()
  const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
  const events = useEvents()
  const source = isNativeApplication ? 'mobile': 'web'

  let newsletter = 'no'
  if (state.communicationConsent) {
    newsletter = 'yes'
  }
  useEffect(() => {
    form.setFieldsValue({
      communicationConsent: ['consent'],
    })
  }, [userDetailsLoading])

  const communicationText = 'I agree to receive information and offers from UCOOK'

  const _handleOneSignalInsert = async (input: {deviceId: string, platformId: string, platformType: UserCommunicationPlatformEnum}): Promise<void> => {
    try {
      await addOneSignalData({
        variables: input,
      })
      setState((prevState) => update(prevState, {
        hasBeenOneSignalInserted: { $set: true },
      }))
      OneSignalReact.login(
        userDetailsData?.currentUser?.id,
      )
      AppPlugin.shared().setIsOneSignalLoggedIn(true)

    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  const _handleSendPhoneVerification = async (): Promise<void> => {
    try {
      await requestPhoneVerification({
        variables: {
          number: userDetailsData?.currentUser?.phone,
        },
        refetchQueries: [{ query: UserDetailsDocument }],
        awaitRefetchQueries: true,
      })
      onSignedUp?.(true, userDetailsData?.currentUser?.phoneIsVerified)
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  const _handleSignupWithGoogle = async (token: string): Promise<void> => {
    const userDataFromGoogle :JwtPayload = jwtDecode(token)

    _handleSocialSignUp({
      platform: SocialPlatformEnum.GOOGLE,
      userId: userDataFromGoogle.sub,
      accessToken: token,
      expiry: addSeconds(new Date(), userDataFromGoogle.exp/ 1000),
    })

    events.hasSignedUp(userDetailsData?.currentUser?.id, SocialPlatformEnum.GOOGLE, newsletter, source )
  } 

  const _handleSignupWithFacebook = async (authResponse: fb.AuthResponse): Promise<void> => {
    _handleSocialSignUp({
      platform: SocialPlatformEnum.FACEBOOK,
      userId: authResponse.userID,
      accessToken: authResponse.accessToken,
      expiry: addSeconds(new Date(), authResponse.expiresIn / 1000),
    })

    events.hasSignedUp(userDetailsData?.currentUser?.id, SocialPlatformEnum.FACEBOOK, newsletter, source)
   
  }

  const _handleSocialSignUp = async (input: SocialLoginInput): Promise<void> => {
    try {
      const signupInput = {
        ...input,
        communicationConsent: state.communicationConsent,
      }
      await socialSignup({
        variables: {
          input: signupInput,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })
    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  const _handleSignUp = async (input: UserSignUpInput): Promise<void> => {
    try {
      const signupInput = {
        ...input,
        communicationConsent: state.communicationConsent,
      }
      await signup({
        variables: {
          input: signupInput,
        },
        refetchQueries: SiteHelper.getUserRefetchQueries(),
        awaitRefetchQueries: true,
      })

      events.hasSignedUp(userDetailsData?.currentUser?.id, 'UCOOK', newsletter, source)

    } catch (e) {
      setState((prevState) => update(prevState, {
        error: { $set: e.message },
      }))
    }
  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'communicationConsent') {
          setState((prevState) => ({ ...prevState, communicationConsent: field.value.length > 0 }))
        }
      })
    })
  }

  const _handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => { SiteHelper.validateAndSubmitFormOnEnter(event, form) }

  useEffect(() => {
    const currentUser = userDetailsData?.currentUser
    if (currentUser?.__typename === 'RegisteredUser') {
      if (currentUser?.phone) {
        _handleSendPhoneVerification()
        const email = currentUser.email
        if (newsletter && state.hasGeneratedLead === false) {
          events.hasGeneratedLead(email, 'Signup')  
          setState((prevState) => update(prevState, {
            hasGeneratedLead: { $set: true },
          }))
        }
      } else {
        onSignedUp?.(false, false)
      }

      if (isNativeApplication) {
        // function found in the app wrapper gonative exclusively

        gonative.onesignal.onesignalInfo().then(function (oneSignalInfo: oneSignalAppInfoInterface) {
          const userCommunicationData = registeredUser?.currentDevice?.userCommunicationPlatformData
          const communicationData = Array(userCommunicationData)[0]
          let isUserCommunicationPlatformRegistered = false
          for (let i = 0; i < Object.keys(communicationData)?.length; i++) {
            if (Object.values(communicationData)[i].platformType === UserCommunicationPlatformEnum.ONE_SIGNAL) {
              isUserCommunicationPlatformRegistered = true
              break
            }
          }

          if (!isUserCommunicationPlatformRegistered) {
            _handleOneSignalInsert({
              deviceId: registeredUser.currentDevice.id,
              platformId: oneSignalInfo.oneSignalUserId,
              platformType: UserCommunicationPlatformEnum.ONE_SIGNAL,
            })
          }
        })

        /* global gonative */
        gonative.onesignal.externalUserId.set({
          externalId: registeredUser.id,
        })
      } else {
        const isSubscribed = OneSignalReact.User.PushSubscription.optedIn
        const userCommunicationData = registeredUser?.currentDevice?.userCommunicationPlatformData
        const communicationData = Array(userCommunicationData)[0]
        let isUserCommunicationPlatformRegistered = false
        for (let i = 0; i < Object.keys(communicationData)?.length; i++) {
          if (Object.values(communicationData)[i].platformType === UserCommunicationPlatformEnum.ONE_SIGNAL) {
            isUserCommunicationPlatformRegistered = true
            break
          }
        }

        if (!isUserCommunicationPlatformRegistered && isSubscribed) {
          _handleOneSignalInsert({
            deviceId: registeredUser.currentDevice.id,
            platformId: registeredUser.id,
            platformType: UserCommunicationPlatformEnum.ONE_SIGNAL,
          })
        } else {
          OneSignalReact.login(
            userDetailsData?.currentUser?.id,
          )
          AppPlugin.shared().setIsOneSignalLoggedIn(true)

        }

      }
    }

  }, [userDetailsData?.currentUser])

  const loading = signupLoading || socialSignupLoading || userDetailsLoading || requestPhoneVerificationLoading

  return (
    <Container $isNativeApplication={isNativeApplication}>
      <Form form={form} onFieldsChange={_handleChange} onFinish={_handleSignUp} disabled={loading}>

        <If condition={!isNativeApplication}>
          <SocialButtonContainer>
            <GoogleIdentitySocialButton
              message='signup'
              disabled={loading}
              didAuthenticateWithGoogle={_handleSignupWithGoogle} />
            <DeviceContainer $mobile $tablet>
              <Spacer universal='8px' />
            </DeviceContainer>
            <DeviceContainer $desktop>
              <Spacer universal='16px' variant='horizontal' />
            </DeviceContainer>
            <FacebookSocialButton
              disabled={loading}
              didAuthenticateWithFacebook={_handleSignupWithFacebook} />
          </SocialButtonContainer>
          <Spacer universal='16px' />
          <TitleSeperator title='OR' />
          <Spacer universal='8px' />
        </If>
        <NameContainer>
          <TextInput
            onKeyDown={_handleKeyDown}
            name='firstName'
            placeholder='Enter your name'
            rules={[{ required: true, message: 'Please input a first name' }]}
            variant='text'
            className='name-input' />
          <NameSpacer />
          <TextInput
            onKeyDown={_handleKeyDown}
            name='lastName'
            placeholder='Enter your surname'
            rules={[{ required: true, message: 'Please input a last name' }]}
            variant='text'
            className='name-input' />
        </NameContainer>
        <Spacer universal='16px' />
        <TextInput
          onKeyDown={_handleKeyDown}
          name='email'
          placeholder='Enter your email'
          rules={[{ required: true, message: 'Please input an email' }]}
          variant='email'
          className='input' />
        <Spacer universal='16px' />
        <TextInput
          onKeyDown={_handleKeyDown}
          name='phone'
          placeholder='Enter your cell number'
          rules={[{ required: true, message: 'Please input a cell number' }]}
          variant='phone'
          className='input' />
        <Spacer universal='16px' />
        <TextInput
          onKeyDown={_handleKeyDown}
          name='password'
          placeholder='Enter your password'
          rules={[{ required: true, message: 'Please input a password' }]}
          variant='password'
          className='input' />
        <CheckBox
          className='check-input'
          name='communicationConsent'
          label={communicationText}
          showLabel={false}
          showOptional={false}
          options={[{ title: communicationText, value: 'consent' }]} />
        <Spacer universal='16px' />
        <If condition={state.error !== ''}>
          <ModalMessageContainer>
            <Paragraph variant='p1' align='center' className='error'>{state.error}</Paragraph>
          </ModalMessageContainer>
          <Spacer universal='24px' />
        </If>
        <Button
          title='SIGN UP'
          disabled={loading}
          color='black'
          fullWidth
          loading={loading}
          onClick={() => form.submit()}/>
        <Spacer mobile='8px' desktop='16px' />
        <Paragraph
          variant='p2'
          color={theme.colors.slates.bitter}
          align='center'>
          By continuing, you acknowledge that you have read and agreed to the
          <TextLink href='https://ucook.co.za/terms' target='_blank'>terms and conditions</TextLink>
          and
          <TextLink href='https://ucook.co.za/privacy-policy' target='_blank'>privacy policy</TextLink>
          of UCOOK.
        </Paragraph>
        <Spacer mobile='8px' desktop='16px' />
        <FooterContainer>
          <Paragraph variant='p2'>Already have an account?</Paragraph>
          <Spacer universal='8px' variant='horizontal' />
          <Link variant='l2' decoration='underline' onClick={onLogIn}> Login </Link>
        </FooterContainer>
      </Form>
    </Container>
  )
}
