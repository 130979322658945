import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

import { Loader, frozenUrls } from '@client/components'
import { Spacer } from '@client/components/atoms'
import { Aisles, CraftMealCard, CraftPizzaCard, CraftDessertCard, CraftKidsCard, MiniProductGrid } from '@client/components/molecules'
import { FrozenMyShopCard } from '@client/components/molecules/stores/FrozenMyShopCard'
import { AislesBaseContainer, AislesLoadingContainer } from '@client/components/Theme'
import { renderMiniProductGrids } from '@client/components/utility/RenderMiniCardGrids'
import { useConfig } from '@client/contexts/ConfigProvider'
import { useGetFrozenAislesQuery, useUserDetailsQuery, useMyShopFrozenAilsesQuery, FrozenMealDishListFragment } from '@hooks/api'
import { FrozenMealDishOrder, FrozenMealDishOrderEnum, FrozenPortionSizeEnum, ProductIsle, ProductRangeEnum } from '@uctypes/api/globalTypes'
import { Mutable } from '@uctypes/global'
import { useEvents } from '@contexts/GTMProvider'
import { Utilities } from '@lib/Utilities'
import { ItemInterfaceNew } from '@lib/GTM'

const AislesContainer = styled.div`
  ${AislesBaseContainer}
`
const LoadingContainer = styled.div`
  ${AislesLoadingContainer}
`

export interface FrozenAisleCarouselProps {
  onDataLoaded?: () => void
  isMiniCard?: boolean
  slidesToScroll?: number
  urlSuffix?: string
  aislesMaxWidth?: string
  dataLimit?: number
  fadeThresholdLimit?: number
}

interface FrozenAisleCarouselState {
  hasLoggedImpression: boolean
}

const DEFAULT_STATE: FrozenAisleCarouselState = {
  hasLoggedImpression: false,
}

export const FrozenAislesCarousel = React.memo(({ onDataLoaded, isMiniCard = false, slidesToScroll = 1, urlSuffix = '', aislesMaxWidth, dataLimit = 20, fadeThresholdLimit }: FrozenAisleCarouselProps): JSX.Element => {

  const [state, setState] = useState<FrozenAisleCarouselState>({ ...DEFAULT_STATE })

  const frozenOrder: Mutable<FrozenMealDishOrder> = {
    field: FrozenMealDishOrderEnum.DISPLAY_INDEX,
  }
  const events = useEvents()
  const { data, loading } = useGetFrozenAislesQuery({ variables: { frozenOrder, limit: dataLimit } })
  const config = useConfig()
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })

  const getAisles = (title: string) => data?.productIsles?.isles?.find(isle => isle.title === title)

  const craftMeals = getAisles('Craft Meals')
  const craftPizzas = getAisles('Craft Pizza')
  const craftDesserts = getAisles('Craft Dessert')
  const craftKids = getAisles('Kids Meals')

  const getFrozenTitle = (title?: string | null | undefined): string => title ? `Frozen ${title}` : 'Frozen'

  const { data: myShopAisleData, loading: myShopLoading } = useMyShopFrozenAilsesQuery({
    variables: {
      limit: dataLimit,
      userId: userDetailsData?.currentUser?.id,
      input: {
        productType: ProductRangeEnum.FROZEN_MEAL,
      },

    },
  })

  const myShopList = myShopAisleData?.myShopAisle
  const boughtBefores = myShopAisleData?.myShopAisle?.list
  const itemsOrdered: any[] = []

  for (let i = 0; i < boughtBefores?.length; i++) {
    itemsOrdered.push(boughtBefores[i].group)
  }

  const renderMiniCardGrid = () => {
    const batchSize = 4
    const miniProductGrids = []

    for (let i = 0; i < itemsOrdered.length; i += batchSize) {
      const batch = itemsOrdered.slice(i, i + batchSize)
      miniProductGrids.push(
        <MiniProductGrid key={`batch${i / batchSize + 1}`} meals={batch} isAisle isMixed />,
      )
    }

    return miniProductGrids
  }

  const displayFade = (list: unknown[]) => fadeThresholdLimit ? list?.length > fadeThresholdLimit : false

  useEffect(() => {
    if (!loading && !state.hasLoggedImpression) {
      events.hasViewedCatalogue(
        craftMeals?.products?.map((item, displayIndex) => {
          const meal = item as FrozenMealDishListFragment

          const logData = {
            itemName: meal?.name,
            itemId: meal?.id,
            price: meal?.products?.find((dish) => dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_FOUR)?.price,
            itemBrand: 'UCOOK',
            itemCategory: meal?.frozenCategories?.map((cat) => cat.id).join(', '),
            itemVariant: meal?.frozenCategories?.map((cat) => cat.title).join(', '),
            itemListName: 'Craft Meals',
            index: displayIndex,
            itemImage: meal?.coverImage?.location,
          }

          const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
          return snakedData
        }),
        userDetailsData?.currentUser?.id)
      events.hasViewedCatalogue(
        craftPizzas?.products?.map((item, displayIndex) => {
          const meal = item as FrozenMealDishListFragment

          const logData = {
            itemName: meal?.name,
            itemId: meal?.id,
            price: meal?.products?.find((dish) => dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_FOUR)?.price,
            itemBrand: 'UCOOK',
            itemCategory: meal?.frozenCategories?.map((cat) => cat.id).join(', '),
            itemVariant: meal?.frozenCategories?.map((cat) => cat.title).join(', '),
            itemListName: 'Craft Pizza',
            index: displayIndex,
            itemImage: meal?.coverImage?.location,
          }

          const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
          return snakedData
        }),
        userDetailsData?.currentUser?.id)
      events.hasViewedCatalogue(
        craftDesserts?.products?.map((item, displayIndex) => {
          const meal = item as FrozenMealDishListFragment
          const logData = {
            itemName: meal?.name,
            itemId: meal?.id,
            price: meal?.products?.find((dish) => dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_FOUR)?.price,
            itemBrand: 'UCOOK',
            itemCategory: meal?.frozenCategories?.map((cat) => cat.id).join(', '),
            itemVariant: meal?.frozenCategories?.map((cat) => cat.title).join(', '),
            itemListName: 'Craft Desserts',
            index: displayIndex,
            itemImage: meal?.coverImage?.location,
          }

          const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
          return snakedData
        }),
        userDetailsData?.currentUser?.id)
      events.hasViewedCatalogue(
        craftKids?.products?.map((item, displayIndex) => {
          const meal = item as FrozenMealDishListFragment
          const logData = {
            itemName: meal?.name,
            itemId: meal?.id,
            price: meal?.products?.find((dish) => dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_FOUR)?.price,
            itemBrand: 'UCOOK',
            itemCategory: meal?.frozenCategories?.map((cat) => cat.id).join(', '),
            itemVariant: meal?.frozenCategories?.map((cat) => cat.title).join(', '),
            itemListName: 'Craft Kids',
            index: displayIndex,
            itemImage: meal?.coverImage?.location,
          }

          const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
          return snakedData
        }),
        userDetailsData?.currentUser?.id)

      events.hasViewedCatalogue(
        myShopList?.list?.map((items, displayIndex) => {
          const item = items as unknown as FrozenMealDishListFragment
         

          const logData = {
            itemName: item?.name,
            itemId: item?.id,
            price: item?.products?.find((dish) => dish.frozenPortionSize === FrozenPortionSizeEnum.SERVES_FOUR)?.price,
            itemBrand: 'UCOOK',
            itemCategory: 'Bought Before Frozen',
            itemVariant: 'Bought Before Frozen',
            itemListName: 'Bought Before Frozen',
            index: displayIndex,
            itemImage: item?.coverImage?.location,
          }

          const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
          return snakedData
        }),
        userDetailsData?.currentUser?.id)
      setState((prevState) => update(prevState, {
        hasLoggedImpression: { $set: true },
      }))
    }
  }, [])

  useEffect(() => {
    if (!!onDataLoaded && !loading) {
      onDataLoaded()
    }
  }, [loading])

  let craftMeal: FrozenMealDishListFragment
  let craftPizza: FrozenMealDishListFragment
  let craftDessert: FrozenMealDishListFragment
  let craftKid: FrozenMealDishListFragment
  let boughtBefore: FrozenMealDishListFragment
  return (
    <Choose>
      <When condition={loading}>
        <LoadingContainer>
          <Loader noShadow />
        </LoadingContainer>
      </When>
      <Otherwise>

        <AislesContainer>
          <If condition={myShopList?.list?.length > 0 && !myShopLoading}>
            <Aisles
              title='Frozen Bought Before'
              viewAllUrl={`${frozenUrls.boughtBeforeUrl}${urlSuffix}`}
              className='aisles'
              isMiniCard={isMiniCard}
              slidesToScroll={slidesToScroll}
              aislesMaxWidth={aislesMaxWidth}
              displayFade={displayFade(myShopList?.list)}>
              <Choose >
                <When condition={!isMiniCard}>
                  <For each='boughtBefore' of={itemsOrdered || []}>
                    <FrozenMyShopCard
                      className='product-card'
                      key={boughtBefore.id}
                      craftMeal={boughtBefore}
                      loading={loading} />
                  </For>
                </When>
                <Otherwise>
                  {renderMiniCardGrid()}
                </Otherwise>
              </Choose>
            </Aisles>
            <Spacer desktop='16px' />
          </If>

          <Aisles
            title={getFrozenTitle(craftMeals.title)}
            viewAllUrl={`${frozenUrls.craftMealUrl}${urlSuffix}`}
            className='aisles'
            isMiniCard={isMiniCard}
            slidesToScroll={slidesToScroll}
            aislesMaxWidth={aislesMaxWidth}
            displayFade={displayFade(craftMeals?.products)}>
            <Choose >
              <When condition={!isMiniCard}>
                <For each='craftMeal' of={craftMeals?.products || []}>
                  <CraftMealCard
                    className='product-card'
                    key={craftMeal.id}
                    craftMeal={craftMeal}
                    loading={loading} />
                </For>
              </When>
              <Otherwise>
                {renderMiniProductGrids(craftMeals.products as FrozenMealDishListFragment[])}
              </Otherwise>
            </Choose>
          </Aisles>
          <Spacer desktop='16px' />
          <Aisles
            title={getFrozenTitle(craftPizzas?.title)}
            viewAllUrl={`${frozenUrls.craftPizzaUrl}${urlSuffix}`}
            className='aisles'
            isMiniCard={isMiniCard}
            slidesToScroll={slidesToScroll}
            aislesMaxWidth={aislesMaxWidth}
            displayFade={displayFade(craftPizzas?.products)}>
            <Choose >
              <When condition={isMiniCard}>
                {renderMiniProductGrids(craftPizzas.products as FrozenMealDishListFragment[])}
              </When>
              <Otherwise>
                <For each='craftPizza' of={craftPizzas?.products || []}>
                  <CraftPizzaCard
                    className='product-card'
                    key={craftPizza.id}
                    craftPizza={craftPizza}
                    loading={loading} />
                </For>
              </Otherwise>
            </Choose>
          </Aisles>
          <Spacer desktop='16px' />
          <Aisles
            title={getFrozenTitle(craftDesserts?.title)}
            viewAllUrl={`${frozenUrls.craftDessertUrl}${urlSuffix}`}
            className='aisles'
            isMiniCard={isMiniCard}
            slidesToScroll={slidesToScroll}
            aislesMaxWidth={aislesMaxWidth}
            displayFade={displayFade(craftDesserts?.products)}>
            <Choose >
              <When condition={isMiniCard}>
                {renderMiniProductGrids(craftDesserts.products as FrozenMealDishListFragment[])}
              </When>
              <Otherwise>
                <For each='craftDessert' of={craftDesserts?.products || []}>
                  <CraftDessertCard
                    className='product-card'
                    key={craftDessert.id}
                    craftDessertMeal={craftDessert}
                    loading={loading} />
                </For>
              </Otherwise>
            </Choose>
          </Aisles>
          <Spacer desktop='16px' />
          <Aisles title={craftKids?.title}
            viewAllUrl={`${frozenUrls.craftKidsUrl}${urlSuffix}`}
            className='aisles'
            isMiniCard={isMiniCard}
            slidesToScroll={slidesToScroll}
            aislesMaxWidth={aislesMaxWidth}
            displayFade={displayFade(craftKids?.products)}>
            <Choose >
              <When condition={isMiniCard}>
                {renderMiniProductGrids(craftKids.products as FrozenMealDishListFragment[])}
              </When>
              <Otherwise>
                <For each='craftKid' of={craftKids?.products || []}>
                  <CraftKidsCard
                    className='product-card'
                    key={craftKid.id}
                    craftKidsMeal={craftKid}
                    loading={loading} />
                </For>
              </Otherwise>
            </Choose>
          </Aisles>
        </AislesContainer>
      </Otherwise>
    </Choose>
  )
})

FrozenAislesCarousel.displayName = 'FrozenAislesCarousel'
